<template>
  <div class="commbox">
    <CompHeader title="高光时刻" />
    <div class="typechage">
      <div class="changebox">
        <div
          class="typeitem"
          v-for="item in typelist"
          :key="item.id"
          @click="changeindex(item.id)"
          :class="{ actypeitem: item.id == acindex }"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
    <!-- 高光时刻 -->
    <div class="listbox" v-show="acindex == 1">
      <div class="bitem" v-for="item in getList" :key="item.id">
        <div class="title">
          <img
            src="../../assets/newImages/title-text1.png"
            v-if="item.type_name == '盲盒开箱'"
            alt=""
          />
          <img
            src="../../assets/newImages/title-text2.png"
            v-if="item.type_name == '盲盒对战'"
            alt=""
          />
          <img
            src="../../assets/newImages/title-text3.png"
            v-if="item.type_name == '幸运饰品'"
            alt=""
          />
        </div>
        <div class="content">
          <div class="imgbox">
            <img :src="item.skins_img" alt="" />
          </div>
          <div class="skinsname">
            {{ item.skins_name }}
          </div>
          <div class="gettime">
            {{ item.created_at }}
          </div>
          <div class="price">
            <CompCoin :num="item.skins_price" />
          </div>
        </div>
      </div>
    </div>
    <!-- 最近获得 -->
    <div class="listbox" v-show="acindex == 2">
      <div class="items" v-for="(item, i) in getperformlist" :key="item.id">
        <div class="numbox">
          <img v-if="i == 0" src="../../assets/newImages/list1.png" alt="" />
          <img v-if="i == 1" src="../../assets/newImages/list2.png" alt="" />
          <img v-if="i == 2" src="../../assets/newImages/list3.png" alt="" />
          <p v-if="i > 2">{{ i + 1 }}</p>
        </div>
        <div class="imgbox">
          <img :src="item.cover" alt="" />
        </div>
        <div class="infobox">
          <div class="skinsname">
            {{ item.name }}
          </div>
          <div class="skinsdr">{{ item.dura_alias }}</div>
          <div class="pricebox">
            <div class="price">
              <CompCoin :num="item.bean" />
            </div>
            <div class="frombox">
              <div class="fromicon"></div>
              {{ item.type_alias }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewlyHighLightApi, getPerformanceApi } from "@/network/api.js";
import { mapState } from "vuex";
export default {
  data() {
    return {
      typelist: [
        {
          id: 1,
          title: "辉煌时刻",
        },
        {
          id: 2,
          title: "最近获得",
        },
      ],
      acindex: 1,
      getList: [],
      getperformlist: [],
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  created() {
    this.getNewlyHighLight();
  },
  methods: {
    changeindex(v) {
      this.acindex = v;
      if (this.acindex == 2) {
        this.getPerformance();
      }
    },

    // 辉煌时刻
    getNewlyHighLight() {
      getNewlyHighLightApi().then((res) => {
        this.getList = res.data.data;
      });
    },
    // 最近获得
    getPerformance() {
      let params = {
        uid: this.user.id,
      };
      console.log(params);

      getPerformanceApi(params).then((res) => {
        console.log(res);

        this.getperformlist = res.data.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.commbox {
  padding-top: 0.5rem;
}
.typechage {
  width: 100%;
  height: 0.58rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .changebox {
    width: 2.1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 0.38rem;
    background: #161514;
    padding: 0 0.04rem;
    .typeitem {
      width: 0.96rem;
      height: 0.3rem;
      display: flex;
      justify-content: center;
      background: url("../../assets/newImages/record-table.png") no-repeat;
      align-items: center;
      font-weight: 400;
      font-size: 0.12rem;
      color: #b79f79;
    }
    .actypeitem {
      background: url("../../assets/newImages/record-actable.png") no-repeat;
      background-size: 100% 100%;
      color: #ffffff;
    }
  }
}
.listbox {
  width: 3.35rem;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .bitem {
    @include bgImg("goods-list-bg");
    width: 100%;
    height: 1.8rem;
    margin-bottom: 0.12rem;
    .title {
      width: 100%;
      height: 0.45rem;
      display: flex;
      justify-content: center;
      align-items: center;
      @include bgImg("big-titlebg");
      img {
        width: 0.75rem;
      }
    }
    .content {
      width: 100%;
      text-align: center;
      .imgbox {
        width: 1.18rem;
        height: 0.7rem;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: 100%;
        }
      }
      .skinsname {
        font-weight: 400;
        font-size: 0.14rem;
        color: #ffffff;
      }
      .gettime {
        font-weight: 400;
        font-size: 0.12rem;
        color: rgba(255, 255, 255, 0.7);
        margin: 0.05rem 0;
      }
    }
  }
  .items {
    width: 100%;
    height: 0.8rem;
    @include bgImg("itemlistbg");
    margin-bottom: 0.1rem;
    display: flex;
    align-items: center;
    .numbox {
      width: 0.28rem;
      height: 0.28rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0.12rem;
      font-weight: 500;
      font-size: 0.2rem;
      color: #b89666;
      img {
        width: 100%;
      }
    }
    .imgbox {
      width: 0.69rem;
      height: 0.52rem;

      img {
        width: 100%;
      }
    }
    .infobox {
      width: 55%;
      margin-left: 0.12rem;
      .skinsname {
        font-weight: 400;
        font-size: 0.12rem;
        color: #ffffff;
      }
      .skinsdr {
        font-weight: 400;
        font-size: 0.12rem;
        color: #ffffff;
        margin-top: 0.05rem;
      }
      .pricebox {
        margin-top: 0.09rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .frombox {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 0.12rem;
          color: #b79f79;
          .fromicon {
            width: 0.04rem;
            height: 0.04rem;
            border-radius: 50%;
            background: #b79f79;
            margin-right: 0.05rem;
          }
        }
      }
    }
  }
}
</style>
